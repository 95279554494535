export const STATUS_PENDING = "PENDING";
export const STATUS_APPROVED = "APPROVED";
export const STATUS_INFORMATION_REQUESTED = "INFORMATION_REQUESTED";
export const STATUS_DENIED = "DENIED";
export const STATUS_REQUIRES_CUSTOMER_ACTION = "REQUIRES_CUSTOMER_ACTION";
export const STATUS_RESTRICTED = "RESTRICTED";
export const STATUS_REQUIRE_APPROVAL = "REQUIRE_APPROVAL";
export const STATUS_PROCESSING = "PROCESSING";
export const STEP_FORM_00 = "t2-form";
export const STEP_FORM_01 = "select-countries";
export const STEP_FORM_02 = "select-products";
export const STEP_FORM_03 = "t3-form";
